import React from "react";
import classes from "./styles.module.css";
import { Container, Row, Col } from "react-bootstrap";
import sectionTwoImg from "../../assets/sectionTwo.png";

const SectionTwo = () => {
  return (
    <div className={`contentContainer ${classes.sectionTwoContainer}`}>
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className={classes.sectionTwoCol1}>
            <img src={sectionTwoImg} alt="Best Capital" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className={classes.sectionTwoCol2}>
            <h2>
              Introducing,{" "}
              <span style={{ color: "#EBC77C" }}>Best Capital Bond</span>
            </h2>
            <p>
              A much-needed solution in today’s environment, that will provide
              you with regular monthly cash flow. This is your chance to NOT
              worry about the volatility of the stock market with a continuous
              revenue stream.Since inception we paid over $346K* in returns to
              our Bondholders and have NOT missed a monthly distribution
            </p>
            <p style={{fontSize: '1.6rem'}}>* The above returns are management prepared and have not been verified by a review by an independent party. Past Performance is not indicative of future returns.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SectionTwo;
