import React from "react";
import classes from "./styles.module.css";
import { Container, Row, Col, Stack, Button } from "react-bootstrap";
import { TelephoneFill, EnvelopeFill } from "react-bootstrap-icons";
import { Linkedin, Facebook, Twitter, Instagram } from "react-bootstrap-icons";
import { useFormik } from "formik";
import * as Yup from "yup";

const Footer = () => {
  // const watchedWebinar = ["DID YOU WATCHED THE WEBINAR", "YES", "NO"];
  // const moreInfo = ["PLEASE SEND ME MORE INFORMATION", "YES", "NO"];

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    onSubmit: function (values) {
      alert("Form Submitted");
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
      phone: Yup.number().required(),
    }),
  });

  return (
    <div className={`contentContainer ${classes.footerContainer}`}>
      <Container fluid>
        <Row className={classes.footerRow1} id="contact">
          <Col xs={12} sm={12} md={6} lg={6} className={classes.footerRow1Col1}>
            <h3>Let’s get started!</h3>
            <Stack gap={4} className={classes.contactList}>
              <div
                className={`${classes.contactPointer} ${classes.contactPointer1}`}
              >
                <div>
                  <TelephoneFill />
                </div>
                <div>
                  <a href="tel:1-888-393-8881">1-888-393-8881</a>
                </div>
              </div>
              <div
                className={`${classes.contactPointer} ${classes.contactPointer1}`}
              >
                <div>
                  <EnvelopeFill />
                </div>
                <div>
                  <a href="mailto:deals@bestcapitalbond.com">
                    deals@bestcapitalbond.com
                  </a>
                </div>
              </div>
            </Stack>
            <Stack gap={4} className={classes.addressList}>
              <div
                className={`${classes.contactPointer} ${classes.contactPointer1}`}
              >
                <div>
                  Suite 200 - 4723 1st Street SW,
                  <br />
                  Calgary, AB, T2G4Y8
                </div>
              </div>
            </Stack>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={classes.contactForm}>
              <form
                method="POST"
                action="/confirmation-page"
                name="contactform"
                className="contactForm"
              >
                <input type="hidden" name="form-name" value="contactForm" />
                <div className={classes.nameField}>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <span className={classes.errorBlock}>
                      {formik.errors.name}
                    </span>
                  )}
                </div>
                <div className={classes.emailField}>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <span className={classes.errorBlock}>
                      {formik.errors.email}
                    </span>
                  )}
                </div>
                <div className={classes.phoneField}>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Cell Phone Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <span className={classes.errorBlock}>
                      {formik.errors.phone}
                    </span>
                  )}
                </div>
                <div>
                  <select name="webinar" id="webinar">
                    <option
                      value="DID YOU WATCHED THE WEBINAR?"
                      selected
                      disabled
                    >
                      DID YOU WATCHED THE WEBINAR?
                    </option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
                <div>
                  <select name="information" id="information">
                    <option
                      value="Please send me more information "
                      selected
                      disabled
                    >
                      Let's get started
                    </option>
                    <option value="Please send me more information">
                      Please send me more information
                    </option>
                    <option value="I'm ready to invest">
                      I'm ready to invest
                    </option>
                  </select>
                </div>
                <div className={classes.submit}>
                  <Button variant="contained" type="submit">
                    Send
                  </Button>
                </div>
              </form>
              <div className={classes.socialLinks}>
                <a
                  href="https://www.facebook.com/BestCapitalBond/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook />
                </a>
                <a
                  href="https://twitter.com/BestCapitalBond/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter />
                </a>
                <a
                  href="https://www.linkedin.com/search/results/all/?keywords=best%20capital%20bond&origin=RICH_QUERY_SUGGESTION&position=0&searchId=d63b15bc-5998-4905-bee9-8c1c507c98be&sid=GFk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Linkedin />
                </a>
                <a
                  href="https://www.instagram.com/bestcapitalbond/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            style={{ textAlign: "center" }}
            className={classes.footerRow2Col}
          >
            <h5>DISCLAIMER</h5>
            <p>
              This website is for information purposes and does not constitute a
              solicitation or offer to purchase securities.
              <br />
              ** There are a number of risks associated with this investment,
              any one of which could adversely affect an investor’s return on
              investment in these securities. These risks are set out in the
              Offering Memorandum of the Corporation relating to this investment
              opportunity. Investors should review these risks with their legal,
              financial and tax advisors. The 8% annual return payable by Best
              Capital Bond I Corp. (the “Corporation”) is not guaranteed and may
              not be paid on a current basis in each year of the bonds or at
              all. The ability of the Corporation to pay the 8% annual return is
              contingent on a number of factors out of the control of the
              Corporation, the primary of which is the payment of principal and
              interest being made by the parties to whom the Corporation
              advances mortgage proceeds. Subscribers should review the risk of
              investing in the bonds of the Corporation with their tax and legal
              advisors.<br></br>&copy; 2022 Best Capital Bond. All rights
              reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
