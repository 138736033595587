import React from "react";
import classes from './styles.module.css';
import Button from "react-bootstrap/esm/Button";

const BestButton = (props) => {
    return (
        <Button href={props.link} className={classes.bestButton}>{props.label}</Button>
    )
}

export default BestButton;