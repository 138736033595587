import React, { useState } from "react";
import classes from "./styles.module.css";
import { Container, Row, Col, Stack } from "react-bootstrap";
import sectionThreeImg from "../../assets/sectionThree.png";
import { CheckCircleFill } from "react-bootstrap-icons";

const SectionThree = () => {

  const [list, setList] = useState(false);

  return (
    <div className={`contentContainer ${classes.sectionThreeContainer}`}>
      <Container fluid>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.sectionThreeCol1}
          >
            <img src={sectionThreeImg} alt="Best Capital" />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.sectionThreeCol2}
          >
            <h2>
              Here Are Some of the{" "}
              <span style={{ color: "#EBC77C" }}>Incredible Benefits</span> of
              Investing in Best Capital Bond…
            </h2>
            <Stack gap={3} className={classes.sectionThreeList}>
              <div className={`${classes.pointer} ${classes.pointer1}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>Investors are paid first (Our investors are paid prior to payment of Administration Fees to the Best Capital Management)</div>
              </div>
              <div className={`${classes.pointer} ${classes.pointer2}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>
                  Our investment objectives are to provide bondholders with a
                  fixed income - 8%** per annum, distributed monthly
                </div>
              </div>
              <div className={`${classes.pointer} ${classes.pointer3}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>We specialize in niche private lending</div>
              </div>
              <div className={`${classes.pointer} ${classes.pointer4}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>
                  We invest in a diversified portfolio of residential mortgages
                  within the Alberta and British Columbia real estate market.
                </div>
              </div>
              <div className={`${classes.pointer} ${classes.pointer5}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>
                  We manage risk through the application of sound credit risk
                  management policies
                </div>
              </div>
            </Stack>
            <Stack gap={3} className={classes.sectionThreeHiddenList} style={{display: list ? 'flex' : 'none'}}>
              <div className={`${classes.pointer} ${classes.pointer1}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>
                  We apply industry knowledge, planning and research on a
                  pro-active basis, to ensure effective operational management
                </div>
              </div>
              <div className={`${classes.pointer} ${classes.pointer2}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>
                Adhere to strict mortgage underwriting criteria
                </div>
              </div>
              <div className={`${classes.pointer} ${classes.pointer3}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>“Sleep at night” reserve fund (held in cash)</div>
              </div>
              <div className={`${classes.pointer} ${classes.pointer4}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>
                  Management also hold bonds in the Corporation
                </div>
              </div>
              <div className={`${classes.pointer} ${classes.pointer5}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>
                No Leverage
                </div>
              </div>
              <div className={`${classes.pointer} ${classes.pointer5}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>
                Quarterly DRIP (Divided Reinvestment Plan) enhances your return
                </div>
              </div>
              <div className={`${classes.pointer} ${classes.pointer5}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>
              All registered plans eligibility (TSFA, RRSP, RESP, LIRA, RRIF, LIF)
                </div>
              </div>
              {/* <div className={`${classes.pointer} ${classes.pointer5}`}>
                <div>
                  <CheckCircleFill />
                </div>
                <div>
                And much more…
                </div>
              </div> */}
            </Stack>
            <button onClick={() => {
              setList(true);
            }} style={{display: list ? 'none' : 'block'}}>Read More</button>
            <button onClick={() => {
              setList(false);
            }} style={{display: !list ? 'none' : 'block'}}>Read Less</button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SectionThree;
