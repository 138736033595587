import React from "react";
import classes from "./styles.module.css";
import { Container, Row, Col } from "react-bootstrap";
import BestButton from "../../components/button/button";

const Banner = () => {
  return (
    <div className={`${classes.bannerContainer} contentContainer`}>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className={classes.bannerCol1}>
            <h2>We Help you Build a Fixed Income Stream</h2>
            <p>Backed by mortgages, paid monthly</p>
            <BestButton link="#contact" label="Get Started"/>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}></Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
