import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Latest from './parts/latestHeader/latest';
import Banner from './parts/banner/banner';
import SectionOne from './parts/sectionOne/sectionOne';
import SectionTwo from './parts/sectionTwo/sectionTwo';
import SectionThree from './parts/sectionThree/sectionThree';
import ReviewSection from './parts/reviewSection/reviewSection';
import SectionFive from './parts/sectionFive/sectionFive';
import Footer from './parts/footer/footer';

function App() {
  return (
    <div className='mainContent'>
      <Latest />
      <Banner />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <ReviewSection />
      <SectionFive />
      <Footer />
    </div>
  );
}

export default App;
