import React from "react";
import classes from "./styles.module.css";
import { Container, Row, Col } from "react-bootstrap";
import quotesImg from "../../assets/quotes.png";
import reviewCardCircle from "../../assets/reviewCardCircle.png";
import Slider from "react-slick";

const ReviewSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div className={`contentContainer ${classes.reviewSectionContainer}`}>
      <Container fluid>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className={classes.reviewSectionCol1}
          >
            <h2>
              Don’t take our word for it — check what our investors are saying.
            </h2>
            {/* <p>A medium through which we could share information with one another</p> */}
          </Col>
          <Col
            xs={12}
            sm={12}
            md={8}
            lg={8}
            className={classes.reviewSectionCol2}
          >
            <Slider {...settings}>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    I'm very happy. This is like a nice bonus - I put money in
                    and forget about it and just check my accounts quarterly.
                    Communication is great and the Best Capital Bond team is
                    easy to work with, nothing to add or improve at this time.
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>Jamie J.</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Understanding gives me piece of mind in the investment world
                    and Michael did a very good job with explaining how the
                    investment will be making money. He was also quick to answer
                    any questions I threw his way in a professional manner.
                    Highly recommended.
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>BRETT TOPOLA</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Best Capital is committed to customer service and and
                    addresses any questions or concerns in a prompt manner. I am
                    been happy with all my dealing with Best Capital since day
                    one.
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>LISD168</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Very professional team, excellent communication and always
                    responsive. Returns have been good so far and online monthly
                    reports make it convenient to easily track.
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>SIMON ROLLAND</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    My experience with Best Capital Bond has been very positive.
                    Communication with Michael has been informative and helpful.
                    My investment has been handled professionally and is
                    performing as expected. I really enjoy the monthly
                    statements that are now being delivered. I also feel good
                    about the charity’s that Best Capital Bond have chosen to
                    support. Thank you.
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>Kelly</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Positive: Professionalism, Quality, Responsiveness, Value
                    Great quality products offering great returns; the staff is
                    friendly knowledgeable and is always available; new products
                    and opportunities and shared and never with pressure. They
                    handle my funds and respect my returns as if it were their
                    own. Highly recommend finding out more about Best capital
                    bond and all else there is to offer. Cheers
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>Guido K</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Positive: Professionalism I'm very happy you are always
                    doing a great service. Investment feels a little boring
                    though. I appreciate your advice and recommendations.
                    Customer service is great - you guys are always there for
                    me. I feel so good when money does something great not just
                    for me and my family but for the community as well.
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>Erika Fernandez</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Konstantin and his team have been so helpful with my
                    investment solutions. Always very responsive and
                    knowledgeable.
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>WES.S</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Positive: Professionalism, Quality, Responsiveness My
                    investing experience has been excellent. When I talk to
                    Konstantin, communication is always clear, and he is always
                    available to answer my questions. The bond fund pays way
                    more than a bank. Donating profits is also a wonderful idea.
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>J.O.</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Positive: Professionalism, Quality, Responsiveness, Value I
                    have invested with Best Capital Bond for a couple of years
                    now and they continue to provide me with excellent service
                    and consistent returns. If you’re looking for a great
                    investment opportunity, call Mike or Konstantin. You won’t
                    be disappointed!
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>James Sheret</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Positive: Professionalism, Quality, Responsiveness, Value
                    ""No worries"" experience. Never thinking about it and
                    getting cheques every month. Communication is really good
                    and I appreciate the support. I like that you are going
                    digital. I think taking extra cash and support charities is
                    an awesome deal - not a lot of fund managers do that.
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>Ron Thorogood</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Very responsive and always on top of it. I like the nice
                    rate of return. Communications are regular and I enjoy
                    watching the videos. I'm very happy with everything and glad
                    I have invested
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>Jessey Camara</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    From the very first moment you meet with the Best Capital
                    Bond team your mind will be at ease and you will know you
                    made the right decision. Bringing their knowledge and
                    insight for investment the team at Best Capital will help
                    you make the best choice for your needs in a very
                    comfortable no pressure environment. Not to mention they
                    walk the talk and you see those positive returns coming in
                    every quarter!!!
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>Heinz Inabnit</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Great company, excellent customer service, easy
                    communication. Highly recommend!
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>Tatiana Drozd</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Since working with Best Capital Bond I have had a consistent
                    and predictable return on my investment month after month!
                    The management team is very knowledgeable and will walk you
                    through step by step how to start making your money work for
                    you.
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>Steven Sheret</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    I'm super happy. Easy to deal with and monthly communication
                    and reporting are great. It is passive and hassle-free. I
                    like it and would definitely recommend it to my friends and
                    family
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>Christian Schaad</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
              <div className={classes.reviewSlider}>
                <div className={classes.reviewCardContainer}>
                  <div className={classes.quotesIcon}>
                    <img src={quotesImg} alt="quotes" />
                  </div>
                  <p>
                    Best Capital Bond provided us with an opportunity to invest
                    at a higher return than we were able to find elsewhere.
                    Their knowledge and experience gave us total confidence in
                    their ability to successfully manage our investments and we
                    would definitely recommend them to fellow investors.
                  </p>
                  <div className={classes.reviewWrittenBy}>
                    <h5>Kathy S</h5>
                    <p>Investor</p>
                  </div>
                  <img
                    src={reviewCardCircle}
                    alt="Circle"
                    className={classes.reviewCircle}
                  />
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
      {/* <Slider {...settings}>
          <div>
            <h3>1</h3>
          </div>
          <div>
            <h3>2</h3>
          </div>
          <div>
            <h3>3</h3>
          </div>
          <div>
            <h3>4</h3>
          </div>
          <div>
            <h3>5</h3>
          </div>
          <div>
            <h3>6</h3>
          </div>
        </Slider> */}
    </div>
  );
};

export default ReviewSection;
