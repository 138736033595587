import React from "react";
import classes from "./styles.module.css";
import { Container, Navbar, Nav,  } from "react-bootstrap";
import logo from '../../assets/logo.png'
import BestButton from "../../components/button/button";

const Latest = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className={classes.headerContainer}>
      <Container fluid className={classes.headerInner}>
        <Navbar.Brand href="#home"><img src={logo} alt='Best Bond Capital'/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className={classes.headerCol2}>
          <Nav className={classes.headerlist}>
            <Nav.Link href="tel:1-888-393-8881"><p>1-888-393-8881</p></Nav.Link>
            <Nav.Link href="#contact">
            <BestButton link="#contact" label="Let's Talk" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Latest;
