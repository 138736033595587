import React from "react";
import classes from "./styles.module.css";
import { Container, Row, Col } from "react-bootstrap";
import sectionOneImg from "../../assets/sectionOne.png";

const SectionOne = () => {
  return (
    <div className={`${classes.sectionOneContainer}`}>
      <Container fluid className={classes.sectionOneBlock}>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className={classes.sectionOneCol1}>
            <h2>
              Are you <span style={{ color: "#EBC77C" }}>worrying</span> about{" "}
              <span style={{ color: "#EBC77C" }}>oil prices</span>, stock{" "}
              <span style={{ color: "#EBC77C" }}>market fluctuation</span>,
              crypto, government, and list goes on and on….?
            </h2>
            <p>
              "It’s no secret that the risk of any investment comes from
              uncertainty, government regulations, and so many other factors.
              <br></br>
              Not to mention, when someone on Twitter can shake the whole world
              nowadays with just one tweet.<br></br>
              And let’s face it,<br></br>
              It might be stressful, and you might be feeling lost in the dark
              waters of finances."
            </p>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className={classes.sectionOneCol2}>
            <div className={classes.sectionOneCol2Img}>
              <img src={sectionOneImg} alt="solution" />
            </div>
            <h2>But here is the thing…</h2>
            <p>
              We have a solution for you to enjoy a stream of monthly
              income.<br></br>
              and you don’t need to check your portfolio daily.<br></br>
              And that’s exactly where this offer of investment into <b>Best
              Capital Bond</b> Fund comes in."
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SectionOne;
