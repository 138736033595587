import React from "react";
import classes from "./styles.module.css";
import { Container, Row, Col } from "react-bootstrap";
import sectionFiveImg from "../../assets/sectionFive.png";
import BestButton from "../../components/button/button";

const SectionFive = () => {
  return (
    <div className={`contentContainer ${classes.sectionFiveContainer}`}>
      <Container fluid>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.sectionFiveCol1}
          >
            <h2>So, What Are You Waiting For…?</h2>
            <p>
              Now is the time to provide a fixed income stream to you and your
              family. The entire process is smooth. Our dedicated and
              experienced team will help you navigate through it and answer all
              your questions – so you will have clarity and peace of mind.
            </p>
            <p>
              To learn more about this opportunity and get started with us,
              scroll below, and answer a few questions.
            </p>
            <BestButton link="#contact" label="Get Started" />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.sectionFiveCol2}
          >
            <img src={sectionFiveImg} alt="Best Capital" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SectionFive;
